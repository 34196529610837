import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AuthGuard } from '../configuration/guards/auth.guard';
import { DataService } from './_services/data.service';
import { ErrorHandelingService } from './_services/error-handeling.service';
import { ErrorsConvertService } from './_services/errors-convert.service';
import { JsonHttpService } from './_services/json-http.service';
import { DisplayErrorsComponent } from './error/display-errors.component';
import { DisplayGlobalErrorsComponent } from './error/display-global-errors.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    declarations: [
        DisplayErrorsComponent,
        DisplayGlobalErrorsComponent
    ],
    providers: [
        AuthGuard,
        DataService,
        ErrorHandelingService,
        ErrorsConvertService,
        JsonHttpService
    ],

    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DisplayErrorsComponent,
        DisplayGlobalErrorsComponent
    ],
})

export class ShareModule { }